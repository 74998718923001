import React from 'react'
import {Link} from 'gatsby'

// Components
import Layout from '../layouts'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
// import TermsOfUse from '../components/TermsOfUse'

// Utils
import {useIsMobile} from '../utils/hooks'

function PrivacyPage() {
  const isMobile = useIsMobile()

  return (
    <Layout pageName="privacy">
      {isMobile ? null : <Nav/>}

      <div className="maxwidth-xsmall">
        <div className="content legal">
          <h1>Privacy Policy</h1>
          <div className="last-revision">Last revision: April 29, 2023</div>
          <h3>Introduction</h3>
          <p>
            Bizy, LLC (“Company”) is committed to protecting your privacy. We
            have prepared this Privacy Policy to describe to you our practices
            regarding the Personal Data (as defined below) we collect from users
            of our Services. When we talk about the “Services” in this policy,
            we are referring to our online workplace productivity tools and
            platform. Our Services are currently available for use via tools
            such as Slack, a web browser or applications specific to your
            desktop or mobile device.
          </p>
          <ol>
            <li>
              <h3>1. Questions; Contacting Company; Reporting Violations</h3>
              <p>
                If you have any questions or concerns or complaints about our
                Privacy Policy or our data collection or processing practices,
                or if you want to report any security violations to us, please
                contact us at the following address or phone number:
              </p>
              <div className="contacts">
                <div className="contacts__item">
                  <p className="contacts__item-title">
                    <strong>Address:</strong>
                  </p>
                  <p className="contacts__item-text">Bizy, LLC</p>
                  <p className="contacts__item-text">712 H Street NE</p>
                  <p className="contacts__item-text">STE 1802</p>
                  <p className="contacts__item-text">Washington, DC 20002</p>
                </div>
                <div className="contacts__item">
                  <p className="contacts__item-title">
                    <strong>Telephone:</strong>
                  </p>
                  <p className="contacts__item-text">800-644-5102</p>
                </div>
              </div>
              <p>
                If you are a resident of a European country participating in the
                Privacy Shield (for more information about Bizy's participation
                in the Privacy Shield see: <a href="/privacy-shield">
                https://www.bizy.com/privacy-shield
              </a> and you believe we do not maintain your personal data within the
                scope of this Privacy Shield certification, you may direct any
                questions or complaints concerning our Privacy Shield compliance
                to <a href="mailto:privacy@bizy.com">privacy@bizy.com</a> or contact us at our mailing address above. We
                will work with
                you to resolve your issue.
              </p>
              <p>
                In the event we are unable to resolve your concern, you may
                contact <a href="https://www.jamsadr.com/eu-us-privacy-shield">JAMS</a>,
                which provides an independent third-party dispute resolution
                body based in the United States, and they will investigate and
                assist you free of charge. A binding arbitration option may also
                be available to you in order to address residual complaints not
                resolved by any other means.
              </p>
              <p>
                You may also be able to invoke binding arbitration for
                unresolved complaints, but prior to initiating such arbitration,
                a resident of Switzerland or a European country participating in
                the Privacy Shield must first: (1) contact us and afford us the
                opportunity to resolve the issue; (2) seek assistance from <a
                href="https://www.jamsadr.com/eu-us-privacy-shield">JAMS</a>;
                and (3) contact the U.S. Department of Commerce (either directly
                or through a European Data Protection Authority) and afford the
                Department of Commerce time to attempt to resolve the issue. If
                such a resident invokes binding arbitration, each party shall be
                responsible for its own attorney’s fees. Please be advised that,
                pursuant to the Privacy Shield, the arbitrator(s) may only
                impose individual-specific, non-monetary, equitable relief
                necessary to remedy any violation of the Privacy Shield
                Principles with respect to the resident.
              </p>
              <p> We are subject to the investigatory and enforcement powers of the FTC, the Department of Transportation or any other U.S. authorized statutory body [currently, there is no other U.S. authorized statutory body recognized by the EU or Switzerland],</p>
            </li>
            <li>
              <h3>2. Use Consent</h3>
              <p>
                By submitting Personal Data through our Site or Services, you
                agree to the terms of this Privacy Policy and you expressly
                consent to the collection, use and disclosure of your Personal
                Data in accordance with this Privacy Policy.
              </p>
            </li>
            <li>
              <h3>3. A Note About Children</h3>
              <p>
                We do not intentionally gather Personal Data from visitors who
                are under the age of 18. If a child under 18 submits Personal
                Data to Company and we learn that the Personal Data is the
                information of a child under 18, we will attempt to delete the
                information as soon as possible. If you believe that we might
                have any Personal Data from a child under 18, please contact us
                at <a href="mailto:legal@bizy.com">legal@bizy.com</a>.
              </p>
            </li>
            <li>
              <h3>4. A Note to Users Outside of the United States</h3>
              <p>
                If you are a non U.S. user of the Site, by visiting the Site and
                providing us with data, you acknowledge and agree that your
                Personal Data may be processed for the purposes identified in
                the Privacy Policy. In addition, your Personal Data may be
                processed in the country in which it was collected and in other
                countries, including the United States, where laws regarding
                processing of Personal Data may be less stringent than the laws
                in your country. By providing your data, you consent to such
                transfer.
              </p>
              <p>
                Individuals located in certain countries, including the European
                Economic Area, have certain statutory rights in relation to
                their personal data. Subject to any exemptions provided by law,
                you may have the right to request access to Information, as well
                as to seek to update, delete or correct this Information. You
                can usually do this using the settings and tools provided in
                your account, or by contacting us at <a href="mailto:privacy@bizy.com">privacy@bizy.com</a>
              </p>
              <p>
                To the extent that Bizy's processing of your Personal Data is
                subject to the General Data Protection Regulation, Bizy relies
                on its legitimate interests, described above, to process your
                data. Bizy may also process Other Information that constitutes
                your Personal Data for direct marketing purposes and you have a
                right to object to Bizy's use of your Personal Data for this
                purpose at any time.
              </p>
            </li>
            <li>
              <h3>5. Types of Data We Collect</h3>
              <p>
                “Personal Data” means data that allows someone to identify or
                contact you, including, for example, your name, address,
                telephone number, e-mail address, as well as any other
                non-public information about you that is associated with or
                linked to any of the foregoing data. “Anonymous Data” means data
                that is not associated with or linked to your Personal Data;
                Anonymous Data does not, by itself, permit the identification of
                individual persons. We collect Personal Data and Anonymous Data,
                as described below.
              </p>
              <h4>Information You Provide to Us</h4>
              <ul>
                <li>
                  <p>
                    We may collect Personal Data from you, such as your first
                    and last name, gender, e-mail and mailing addresses, and
                    password when you create an account to log in to our network
                    (“Account”). If you use another online identity provider to
                    log in to our network, we may collect your Personal Data
                    from that provider under the terms of our agreement with
                    them.
                  </p>
                </li>
                <li>
                  <p>
                    If you use our Services on your mobile device, we may
                    collect your phone number and the unique device id number.
                  </p>
                </li>
                <li>
                  <p>
                    If you tell us where you are (e.g., by allowing your mobile
                    device to send us your location), we may store and use that
                    information to provide you with location-based information.
                  </p>
                </li>
                <li>
                  <p>
                    Our Services may let you store preferences like your teams
                    and time zone settings. We may associate these choices with
                    your ID or mobile device, and you can edit these preferences
                    at any time.
                  </p>
                </li>
                <li>
                  <p>
                    When connecting to our Services via a service provider that
                    uniquely identifies your mobile device, we may receive this
                    identification and use it to offer extended services and/or
                    functionality.
                  </p>
                </li>
                <li>
                  <p>
                    Certain Services may require our collection of your phone
                    number. We may associate that phone number to your mobile
                    device identification information.
                  </p>
                </li>
                <li>
                  <p>
                    When you order Services on our Application, we will collect
                    all information necessary to complete the transaction,
                    including your name, credit card information, billing
                    information and shipping information.
                  </p>
                </li>
                <li>
                  <p>
                    We retain information on your behalf, such as files and
                    messages that you store using your Account.
                  </p>
                </li>
                <li>
                  <p>
                    If you provide us feedback or contact us via e-mail, we will
                    collect your name and e-mail address, as well as any other
                    content included in the e-mail, in order to send you a
                    reply.
                  </p>
                </li>
                <li>
                  <p>
                    When you post content (text, images, photographs, messages,
                    comments or any other kind of content that is not your
                    e-mail address) on our Services, the information contained
                    in your posting will be stored in our servers and made
                    available to the other users on the Services depending on
                    the particular sharing settings you used to post content on
                    the Services.
                  </p>
                </li>
                <li>
                  <p>
                    We also collect other types of Personal Data that you
                    provide to us voluntarily, such as your operating system and
                    version, product registration number, and other requested
                    information if you contact us via e-mail regarding support
                    for the Services.
                  </p>
                </li>
                <li>
                  <p>
                    We may also collect Personal Data at other points in our
                    Services that state that Personal Data is being collected.
                  </p>
                </li>
              </ul>
              <h4>Information Collected via Technology</h4>
              <p className="small-heading">
                <strong>Information Collected by Our Servers:</strong>
              </p>
              <p>
                To make our Services more useful to you, our servers (which may
                be hosted by a third party service provider) collect information
                from you, including your browser type, operating system,
                Internet Protocol (“IP”) address (a number that is automatically
                assigned to your computer when you use the Internet, which may
                vary from session to session), domain name, and/or a date/time
                stamp for your visit.
              </p>
              <p className="small-heading">
                <strong>Log Files:</strong>
              </p>
              <p>
                As is true of most websites, we gather certain information
                automatically and store it in log files. This information
                includes IP addresses, browser type, Internet service provider
                (“ISP”), referring/exit pages, operating system, date/time
                stamp, and clickstream data. We use this information to analyze
                trends, administer the Site, track users’ movements around the
                Site, gather demographic information about our user base as a
                whole, and better tailor our Services to our users’ needs. For
                example, some of the information may be collected so that when
                you visit the Site or the Services again, it will recognize you
                and the information could then be used to serve other
                information appropriate to your interests. Except as noted in
                this Privacy Policy, we do not link this automatically-collected
                data to Personal Data.
              </p>
              <p className="small-heading">
                <strong>Cookies:</strong>
              </p>
              <p>
                Like many online services, we use cookies to collect
                information. “Cookies” are small pieces of information that a
                website sends to your computer’s hard drive while you are
                viewing the website. We may use both session Cookies (which
                expire once you close your web browser) and persistent Cookies
                (which stay on your computer until you delete them) to provide
                you with a more personal and interactive experience on our Site.
                This type of information is collected to make the Site more
                useful to you and to tailor the experience with us to meet your
                special interests and needs. Please see our <Link to="/cookies">Cookie Policy</Link> for more
                information.
              </p>
              <p className="small-heading">
                <strong>Pixel Tags:</strong>
              </p>
              <p>
                In addition, we use “Pixel Tags” (also referred to as clear
                Gifs, Web beacons, or Web bugs). Pixel Tags are tiny graphic
                images with a unique identifier, similar in function to Cookies,
                that are used to track online movements of Web users. In
                contrast to Cookies, which are stored on a user’s computer hard
                drive, Pixel Tags are embedded invisibly in Web pages. Pixel
                Tags also allow us to send e-mail messages in a format users can
                read, and they tell us whether e-mails have been opened to
                ensure that we are sending only messages that are of interest to
                our users. We may use this information to reduce or eliminate
                messages sent to a user.
              </p>
              <p className="small-heading">
                <strong>Mobile Services:</strong>
              </p>
              <p>
                We may also collect non-personal information from your mobile
                device if you use it to access our Services. This information is
                generally used to help us deliver the most relevant information
                to you. Examples of information that may be collected and used
                include your geographic location, how you use the Services, and
                information about the type of device you use. In addition, in
                the event our Services crash on your mobile device, we will
                receive information about your mobile device model software
                version and device carrier, which allows us to identify and fix
                bugs and otherwise improve the performance of our Services. This
                information is sent to us as aggregated information and is not
                traceable to any individual and cannot be used to identify an
                individual.
              </p>
              <p className="small-heading">
                <strong>Analytics:</strong>
              </p>
              <p>
                We use Google Analytics and other similar services to help
                analyze how users use the Services. Google Analytics uses
                Cookies to collect information such as how often users visit the
                Site, what pages they visit, and what other sites they used
                prior to coming to the Site. We use the information we get from
                Google Analytics and other similar services only to improve our
                Site and Services. Google Analytics collects only the IP address
                assigned to you on the date you visit the Site, rather than your
                name or other personally identifying information. Although
                Google Analytics plants a persistent Cookie on your web browser
                to identify you as a unique user the next time you visit the
                Site, the Cookie cannot be used by anyone but Google. Google’s
                ability to use and share information collected by Google
                Analytics about your visits to the Site is restricted by the
                Google Analytics Terms of Use and the Google Privacy Policy.
              </p>
              <p className="small-heading">
                <strong>Google Account Data:</strong>
              </p>
              <p>
                Some of our Services allow you to link your Google Account to
                our Services in order to manipulate your Google Account
                information directly from our Services (e.g. send and receive
                your Google Account e-mail within the Bizy Services). When you
                link your Google Account to the Services, we ask for your
                permission to access your Google Account via APIs provided by
                Google. We will only use those APIs according to the permission
                that you grant, and only to access your information in order to
                provide you the Services and to execute requests that you make
                (e.g. sending e-mail) using the Services.
              </p>
            </li>
            <li>
              <h3>6. Use of Your Personal Data</h3>
              <p>
                Bizy uses Personal Data in furtherance of our legitimate
                interests in operating our Application, Site and Services. More
                specifically:
              </p>
              <h4>General Use</h4>
              <p>
                In general, Personal Data you submit to us is used either to
                respond to requests that you make, or to aid us in serving you
                better. We use your Personal Data in the following ways:
              </p>
              <ul>
                <li>
                  <p>
                    Facilitate the creation of and secure your Account on our
                    network;
                  </p>
                </li>
                <li>
                  <p>Identify you as a user in our system;</p>
                </li>
                <li>
                  <p>
                    Provide improved administration of our Site and Services;
                  </p>
                </li>
                <li>
                  <p>Provide the Services you request;</p>
                </li>
                <li>
                  <p>
                    Improve the quality of experience when you interact with our
                    Site and Services;
                  </p>
                </li>
                <li>
                  <p>
                    Send you a welcome e-mail to verify ownership of the e-mail
                    address provided when your Account was created;
                  </p>
                </li>
                <li>
                  <p>
                    Send you administrative e-mail notifications, such as
                    security or support and maintenance advisories;
                  </p>
                </li>
                <li>
                  <p>
                    Make telephone calls to you, from time to time, to solicit
                    your feedback; and
                  </p>
                </li>
                <li>
                  <p>
                    Send newsletters, surveys, offers, and other promotional
                    materials related to our Services and for other marketing
                    purposes of Company.
                  </p>
                </li>
              </ul>
              <h4>User Testimonials and Feedback</h4>
              <p>
                We often receive testimonials and comments from users who have
                had positive experiences with our Services. We occasionally
                publish such content. When we publish this content, we may
                identify our users by their first and last name and may also
                indicate their home city. We obtain the user’s consent prior to
                posting his or her name along with the testimonial. We may post
                user feedback on the Site from time to time. We will share your
                feedback with your first name and last initial only. If we
                choose to post your first and last name along with your
                feedback, we will obtain your consent prior to posting you name
                with your feedback. If you make any comments on a blog or forum
                associated with your Site, you should be aware that any Personal
                Data you submit there can be read, collected, or used by other
                users of these forums, and could be used to send you unsolicited
                messages. We are not responsible for the personally identifiable
                information you choose to submit in these blogs and forums.
              </p>
              <h4>Creation of Anonymous Data</h4>
              <p>
                We may create Anonymous Data records from Personal Data by
                excluding information (such as your name) that makes the data
                personally identifiable to you. We use this Anonymous Data to
                analyze request and usage patterns so that we may enhance the
                content of our Services and improve Site navigation. We reserve
                the right to use Anonymous Data for any purpose and disclose
                Anonymous Data to third parties in our sole discretion.
              </p>
            </li>
            <li>
              <h3>7. Disclosure of Your Personal Data</h3>
              <p>
                We disclose your Personal Data as described below and as
                described elsewhere in this Privacy Policy.
              </p>
              <h4>Third Parties Designated by You</h4>
              <p>
                When you use the Services, the Personal Data you provide will be
                shared with the third parties that you designate to receive such
                information, including other websites, your friends, relatives
                and business associates. Depending on the type of access you
                grant to such third parties, they may also be permitted to edit
                the information you have provided to us and to designate others
                to access and edit such information.
              </p>
              <h4>Users</h4>
              <p>
                We will share your Personal data with other Users solely for the
                purpose of providing the Services.
              </p>
              <h4>Third Party Service Providers</h4>
              <p>
                We may share your Personal Data with third party service
                providers to: provide you with the Services that we offer you
                through our Site; to conduct quality assurance testing; to
                facilitate creation of accounts; to provide technical support;
                and/or to provide other services to the Company. These third
                party service providers are required not to use your Personal
                Data other than to provide the services requested by Company.
              </p>
              <h4>Affiliates</h4>
              <p>
                We may share some or all of your Personal Data with our parent
                company, subsidiaries, joint ventures, or other companies under
                a common control (“Affiliates”), in which case we will require
                our Affiliates to honor this Privacy Policy.
              </p>
              <h4>Corporate Restructuring</h4>
              <p>
                We may share some or all of your Personal Data in connection
                with or during negotiation of any merger, financing, acquisition
                or dissolution transaction or proceeding involving sale,
                transfer, divestiture, or disclosure of all or a portion of our
                business or assets. In the event of an insolvency, bankruptcy,
                or receivership, Personal Data may also be transferred as a
                business asset. If another company acquires our company,
                business, or assets, that company will possess the Personal Data
                collected by us and will assume the rights and obligations
                regarding your Personal Data as described in this Privacy
                Policy.
              </p>
              <h4>Other Disclosures</h4>
              <p>
                Regardless of any choices you make regarding your Personal Data
                (as described below), Company may disclose Personal Data if it
                believes in good faith that such disclosure is necessary (a) in
                connection with any legal investigation; (b) to comply with
                relevant laws or to respond to subpoenas or warrants served on
                Company; (c) to protect or defend the rights or property of
                Company or users of the Site or Services; and/or (d) to
                investigate or assist in preventing any violation or potential
                violation of the law, this Privacy Policy, or our Terms of Use.
              </p>
              <h4>Third-Party Data Sharing Under Privacy Shield</h4>
              <p>
                number of third-party providers as outlined in our privacy
                policy. If we receive personal data subject to our certification
                under the Privacy Shield (for more information about Bizy's
                participation in the Privacy Shield see: <a
                href="/privacy-shield"> https://www.bizy.com/privacy-shield </a> and then transfer it to a third-party
                service provider acting as
                an agent on our behalf, we have certain liability under the
                Privacy Shield if both (i) the agent processes the personal data
                in a manner inconsistent with the Privacy Shield and (ii) we are
                responsible for the event giving rise to the damage.
              </p>
            </li>
            <li>
              <h3>8. Third Party Websites</h3>
              <p>
                Our Site may contain links to third party websites. When you
                click on a link to any other website or location, you will leave
                our Site and go to another site, and another entity may collect
                Personal Data or Anonymous Data from you. We have no control
                over, do not review, and cannot be responsible for, these
                outside websites or their content. Please be aware that the
                terms of this Privacy Policy do not apply to these outside
                websites or content, or to any collection of your Personal Data
                after you click on links to such outside websites. We encourage
                you to read the privacy policies of every website you visit. The
                links to third party websites or locations are for your
                convenience and do not signify our endorsement of such third
                parties or their products, content or websites.
              </p>
            </li>
            <li>
              <h3>9. Your Choices Regarding Information</h3>
              <p>
                You have several choices regarding the use of information on our
                Service:
              </p>
              <h4>Email Communications</h4>
              <p>
                We may send you Service and administrative emails and messages.
                We may also contact you to inform you about changes in our
                Services, our Service offerings, and important Service related
                notices, such as security and fraud notices. These emails and
                messages are considered part of the Services and you may not
                opt-out of them. In addition, we sometimes send promotional
                emails about our Company. You can opt out of these at any time
                by following the instructions in the email.
              </p>
              <h4>Cookies</h4>
              <p>
                If you decide at any time that you no longer wish to accept
                Cookies from our Service for any of the purposes described
                above, then you can instruct your browser, by changing its
                settings, to stop accepting Cookies or to prompt you before
                accepting a Cookie from the websites you visit. Consult your
                browser’s technical information. If you do not accept Cookies,
                however, you may not be able to use all portions of the Service
                or all functionality of the Service. If you have any questions
                about how to disable or modify Cookies, please let us know at
                the contact information provided below.
              </p>
              <h4>Changing or Deleting Your Personal Data</h4>
              <p>
                You may change any of your Personal Data in your Account by
                editing your profile within your Account or by sending an e-mail
                to us at <a href="mailto:privacy@bizy.com">privacy@bizy.com</a>. You may
                request deletion of your Personal Data by us, and we will use
                commercially reasonable efforts to honor your request, but
                please note that we may be required to keep such information and
                not delete it (or to keep this information for a certain time,
                in which case we will comply with your deletion request only
                after we have fulfilled such requirements). When we delete any
                information, it will be deleted from the active database, but
                may remain in our archives. We may also retain your information
                for fraud or similar purposes.
              </p>
              <h4>Applications</h4>
              <p>
                You can stop all collection of information by the Application by
                uninstalling the Application. You may use the standard uninstall
                processes as may be available as part of your mobile device or
                via the mobile application marketplace or network.
              </p>
            </li>
            <li>
              <h3>10. Security of Your Personal Data</h3>
              <p>
                Company is committed to protecting the security of your Personal
                Data. We use a variety of industry-standard security
                technologies and procedures to help protect your Personal Data
                from unauthorized access, use, or disclosure. We also require
                you to enter a password or use a password-protected third party
                service to access your Account information. Please do not
                disclose your Account password or any third party service
                password to unauthorized people. No method of transmission over
                the Internet, or method of electronic storage, is 100% secure,
                however. Therefore, while Company uses reasonable efforts to
                protect your Personal Data, Company cannot guarantee its
                absolute security.
              </p>
            </li>
            <li>
              <h3>11. EU-U.S. Privacy Shield and Swiss-U.S. Privacy Shield</h3>
              <p>
                Bizy has self-certified to the EU-U.S. and Swiss-U.S. Privacy
                Shield frameworks set forth by the U.S Department of Commerce
                with respect to collection, use and retention of Customer Data.
                For more information, see our Privacy Shield Notice <a
                href="/privacy-shield">(https://www.bizy.com/privacy-shield)</a>. We may process some personal data
                from individuals or
                companies via other compliance mechanisms, including data
                processing agreements based on the EU Standard Contractual
                Clauses. To learn more about the Privacy Shield program, refer
                to <a href="https://www.privacyshield.gov/welcome">(https://www.privacyshield.gov/welcome)</a>.
              </p>
            </li>
            <li>
              <h3>12. Data Protection Authorities</h3>
              <p>
                Bizy commits to cooperate with EU data protection authorities
                (DPAs) and the Swiss Federal Data Protection and Information
                Commissioner (FDPIC) and comply with the advice given by such
                authorities with regard to human resources data transferred from
                the EU and Switzerland in the context of the employment
                relationship.
              </p>
            </li>
            <li>
              <h3>13. Changes to This Privacy Policy</h3>
              <p>
                This Privacy Policy may be updated from time to time for any
                reason. We will notify you of any changes to our Privacy Policy
                by posting the new Privacy Policy here and we will change the
                “Last Updated” date above. You should consult this Privacy
                Policy regularly for any changes.
              </p>
            </li>
          </ol>
        </div>
      </div>

      {isMobile ? null : <Footer/>}
    </Layout>
  )
}

export default PrivacyPage
